import React, { FC, useState, useEffect, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  CardMedia,
  makeStyles,
  Theme,
  createStyles,
  CardActionArea,
} from '@material-ui/core';

import { Storage } from 'aws-amplify';
import { uploadErrorLog } from 'utils/awsUtils';
import { createErrorObject } from 'modules/commonModule';
import {
  NO_IMAGE,
  IMAGE_MIME,
  IMAGE_QUALITY,
  IMAGE_BASE64_PREFIX,
} from 'app/constants';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import PhotoModal from './PhotoModal';

export type WorkResultLocationState = {
  orderId: string;
  clothingId: string;
  tagNo: string;
  clothingTypeCode: number;
  clothingTypeName: string;
  clothingTypePictureFront: string;
  clothingTypePictureBack: string;
};

/**
 * スタイル定義
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    photoBox: {
      position: 'relative',
    },
    photoButton: {
      '&::before': {
        content: "''",
        display: 'block',
        paddingTop: '160%',
      },
    },
    photoImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
    },
    photoNoImage: {
      width: '100%',
      transform: 'rotate(0deg)',
    },
    caption: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: '100%',
      position: 'relative',
    },
    before: {
      backgroundColor: theme.palette.common.white,
      '&::after': {
        content: "''",
        width: 0,
        height: 0,
        zIndex: 1,
        borderStyle: 'solid',
        borderWidth: '10px 0 10px 20px',
        position: 'absolute',
        right: '-20px',
        top: '50%',
        transform: 'translateY(-50%)',
        borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
      },
      '& p': {
        fontWeight: 700,
        textAlign: 'center',
      },
    },
    after: {
      backgroundColor: '#C9E4FF',
      '& p': {
        fontWeight: 700,
        textAlign: 'center',
      },
    },
  }),
);

interface PhotoProps {
  type: string;
  beforePhoto: string | null;
  afterPhoto: string | null;
  clothingTypeName: string;
  workContentName: string;
}
const Photo: FC<PhotoProps> = ({
  type,
  beforePhoto,
  afterPhoto,
  clothingTypeName,
  workContentName,
}) => {
  const classes = useStyles();
  const [beforePhotoUrl, setBeforePhotoUrl] = useState('');
  const [afterPhotoUrl, setAfterPhotoUrl] = useState('');
  const [isOpenPhotoModal, setIsOpenPhotoModal] = useState(false);
  const history = useHistory();
  const location = useLocation<WorkResultLocationState>();

  /**
   * 写真を回転させる
   * @param image イメージ
   */
  const rotateImage = useCallback(async (image: string) => {
    const imageUrl = (await Storage.get(image)).toString();
    const s3ImageResponse = await axios.get(imageUrl, {
      responseType: 'arraybuffer',
    });

    const img = new Image();
    img.src = `${IMAGE_BASE64_PREFIX}${Buffer.from(
      s3ImageResponse.data,
      'binary',
    ).toString('base64')}`;

    const onLoadImage = new Promise((resolve) => {
      img.addEventListener('load', () => {
        resolve();
      });
    });

    await onLoadImage;

    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');
    if (ctx) {
      if (img.height < img.width) {
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.transform(0, 1, -1, 0, img.height, 0);
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }
      ctx.drawImage(img, 0, 0);
    }

    return canvas.toDataURL(IMAGE_MIME, IMAGE_QUALITY);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (beforePhoto) {
          const rotatedImage = await rotateImage(beforePhoto);
          setBeforePhotoUrl(rotatedImage);
        }
      } catch (e) {
        await uploadErrorLog(
          'Photo',
          createErrorObject('画像を取得できませんでした。', e),
        );
      }
    })();
  }, [beforePhoto, rotateImage]);

  useEffect(() => {
    (async () => {
      try {
        if (afterPhoto) {
          const rotatedImage = await rotateImage(afterPhoto);
          setAfterPhotoUrl(rotatedImage);
        }
      } catch (e) {
        await uploadErrorLog(
          'Photo',
          createErrorObject('画像を取得できませんでした。', e),
        );
      }
    })();
  }, [afterPhoto, rotateImage]);

  /**
   * 画像ポップアップを開く
   */
  const openPhotoModal = useCallback(() => {
    setIsOpenPhotoModal(true);
    history.push('/workresult', location.state);
  }, [history, location.state]);

  /**
   * 画像ポップアップを閉じる
   */
  const closePhotoModal = useCallback(() => {
    setIsOpenPhotoModal(false);
  }, []);

  return (
    <>
      <Box className={classes.photoBox}>
        <CardActionArea
          data-testid="photo-button"
          className={classes.photoButton}
          onClick={openPhotoModal}
        >
          {type === 'Before' && (
            <CardMedia
              component="img"
              image={beforePhotoUrl || NO_IMAGE}
              className={classes.photoImage}
              data-testid="before-photo-image"
            />
          )}
          {type === 'After' && (
            <CardMedia
              component="img"
              image={afterPhotoUrl || NO_IMAGE}
              className={classes.photoImage}
              data-testid="after-photo-image"
            />
          )}
        </CardActionArea>
        <Box
          className={`${classes.caption} ${
            type === 'Before' && classes.before
          } ${type === 'After' && classes.after}`}
        >
          <Typography component="p" variant="h5">
            {type}
          </Typography>
        </Box>
      </Box>

      <PhotoModal
        type={type}
        beforePhotoUrl={beforePhotoUrl || null}
        afterPhotoUrl={afterPhotoUrl || null}
        clothingTypeName={clothingTypeName}
        workContentName={workContentName}
        open={isOpenPhotoModal}
        handleClose={closePhotoModal}
      />
    </>
  );
};
export default Photo;
