/**
 * ログインページ
 *************************************************/
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
  Input,
  CardMedia,
  Typography,
} from '@material-ui/core';
import ActionButton from 'components/common/ActionButton';
import { startLoading, endLoading } from 'modules/commonModule';
import { useForm } from 'react-hook-form';
import { pageStyles } from 'components/Login/Styles';
import { getOrderId } from 'api/orders/orders.api';
import { getClothingsByOrderId } from 'api/clothings/clothings.api';

type FormData = {
  email: string;
  ecOrderNo: string;
};
const LoginPage: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageClass = pageStyles();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { register, handleSubmit, errors } = useForm<FormData>({
    defaultValues: {
      email: '',
      ecOrderNo: '',
    },
  });

  /**
   * ログイン処理
   */
  const onSubmit = handleSubmit(async ({ email, ecOrderNo }) => {
    try {
      dispatch(startLoading());

      // 注文IDを取得
      // ログイン以降はこの注文IDを保持し、ログインフラグとしても利用する
      const order = await getOrderId(email, ecOrderNo);

      if (order) {
        // 衣類情報を取得
        const clothingList = await getClothingsByOrderId(order.orderId);

        // 衣類情報が取得できなかった場合は、おせっかい対象衣類が存在しない注文と判定する
        if (!clothingList) {
          setErrorMessage(
            '入力いただいたご注文にはおせっかい対象のご洋服がありませんでした。',
          );
          dispatch(endLoading());

          return;
        }

        // 衣類情報一覧画面へ遷移
        history.push('/', {
          orderId: order.orderId,
          clothingList,
        });
      }
    } catch (e) {
      setErrorMessage('ご入力内容に該当する情報はありませんでした。');
      dispatch(endLoading());
    }
  });

  return (
    <CardMedia
      className={pageClass.loginBackGround}
      image={`${process.env.PUBLIC_URL}/loginBack.png`}
    >
      <form onSubmit={onSubmit} noValidate>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={pageClass.formInner}
        >
          <Grid item xs={9} lg={2}>
            <Box className={pageClass.logo}>
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" />
            </Box>
            {!!errorMessage && (
              <Box className={pageClass.errorMessageBox}>
                <Typography
                  component="p"
                  variant="h4"
                  className={`${pageClass.errorMessage}`}
                >
                  {errorMessage}
                </Typography>
              </Box>
            )}
            <Box className={pageClass.inputEmail}>
              <FormControl fullWidth error={!!errors.email}>
                <InputLabel htmlFor="email">メールアドレス</InputLabel>
                <Input
                  name="email"
                  id="email"
                  type="email"
                  inputMode="email"
                  autoComplete="on"
                  autoFocus
                  inputRef={register({
                    required: 'メールアドレスを入力してください。',
                    maxLength: {
                      value: 60,
                      message: '60文字以下で入力してください。',
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'メールアドレスの形式で入力してください。',
                    },
                  })}
                  aria-describedby="emailNoHelperText"
                  inputProps={{
                    'data-cy': 'email-input',
                  }}
                />
                {!!errors.email && (
                  <FormHelperText id="emailNoHelperText">
                    {errors.email.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box className={pageClass.inputEcOrderNo}>
              <FormControl fullWidth error={!!errors.ecOrderNo}>
                <InputLabel htmlFor="ecOrderNo">ご注文番号</InputLabel>
                <Input
                  name="ecOrderNo"
                  id="ecOrderNo"
                  type="tel"
                  autoComplete="off"
                  inputRef={register({
                    required: 'ご注文番号を入力してください。',
                    maxLength: {
                      value: 50,
                      message: '50文字以下で入力してください。',
                    },
                    pattern: {
                      value: /[0-9]/,
                      message: 'ご注文番号は数字のみで入力してください。',
                    },
                  })}
                  aria-describedby="ecOrderNoHelperText"
                  inputProps={{
                    'data-cy': 'ecOrderNo-input',
                  }}
                />
                {!!errors.ecOrderNo && (
                  <FormHelperText id="ecOrderNoHelperText">
                    {errors.ecOrderNo.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box>
              <ActionButton
                type="submit"
                fullWidth
                color="primary"
                data-cy="login-button"
              >
                ログイン
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </CardMedia>
  );
};
export default LoginPage;
