/*
 * スタイル定義
 * 作業情報一覧ページ
 */
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { fontTheme } from 'components/common/Theme';

export const pageStyles = makeStyles((theme: Theme) =>
  createStyles({
    secInner: {
      width: '100%',
      maxWidth: '1280px',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    catchSec: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(12),
      background: theme.palette.common.white,
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(30),
        paddingBottom: theme.spacing(30),
      },
    },
    catch: {
      textAlign: 'left',
      lineHeight: 1.7,
      fontFamily: fontTheme.fontFamily.mincho,
      [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
      },
    },
    catchHead: {
      marginBottom: theme.spacing(7),
      letterSpacing: '.14em',
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.h1.fontSize,
        color: theme.typography.h1.color,
        '& span': {
          display: 'block',
          marginBottom: theme.spacing(1),
        },
      },
    },
    catchBody: {
      letterSpacing: '.12em',
      [theme.breakpoints.up('sm')]: {
        '& span': {
          display: 'block',
          marginBottom: theme.spacing(1),
          fontSize: theme.typography.h4.fontSize,
          color: theme.typography.h4.color,
        },
      },
    },
    orderSec: {
      paddingTop: theme.spacing(9),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(20),
      },
    },
    orderContainer: {
      marginBottom: theme.spacing(5),
    },
    orderItem: {
      textAlign: 'center',
      backgroundImage: `url(${process.env.PUBLIC_URL}/texture02.png)`,
      '&:first-of-type': {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        width: '48%',
        flexBasis: '48%',
        '&:first-of-type': {
          marginBottom: 0,
        },
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
      [theme.breakpoints.up('md')]: {
        '&:first-of-type': {
          marginBottom: theme.spacing(8),
        },
        '&:last-of-type': {
          marginBottom: theme.spacing(8),
        },
      },
    },
    orderHead: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      color: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(5),
      },
    },
    orderBody: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(7),
      },
      '& span': {
        fontWeight: theme.typography.fontWeightBold,
        marginLeft: theme.spacing(1),
        color: theme.palette.common.white,
      },
    },
    clothingList: {
      '&:before': {
        content: '""',
        display: 'block',
        width: '48%',
        height: 0,
        order: 1,
        [theme.breakpoints.up('sm')]: {
          width: '32%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '23%',
        },
      },
      '&:after': {
        content: '""',
        display: 'block',
        width: '48%',
        height: 0,
        [theme.breakpoints.up('sm')]: {
          width: '32%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '23%',
        },
      },
    },
    clothingItem: {
      width: '48%',
      flexBasis: '48%',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        width: '32%',
        flexBasis: '32%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '23%',
        flexBasis: '23%',
      },
    },
  }),
);
