import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Paper,
  withStyles,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import SwipeableViews from 'react-swipeable-views';
import { NO_IMAGE } from 'app/constants';
import { useHistory } from 'react-router-dom';
import { PinchZoom } from 'react-ivy-pinch-zoom';
import 'react-ivy-pinch-zoom/dist/pinch-zoom.css';

/**
 * スタイル定義
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    PhotoModal: {
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '5px',
      zIndex: 2,
      cursor: 'pointer',
    },
    photoBox: {
      position: 'relative',
      overflow: 'hidden',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    photoBoxInner: {
      '& .pz-zoom-button': {
        display: 'none',
      },
    },
    caption: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: '100%',
      position: 'relative',
    },
    typeArea: {
      width: '180px',
      position: 'absolute',
      left: '50%',
      zIndex: 1,
      transform: 'translateY(-50%) translateX(-50%)',
      overflow: 'hidden',
    },
    type: {
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: 'inline-block',
      transition: 'transform 0.4s',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    typeAfterActive: {
      position: 'absolute',
      transform: 'translateX(0)',
    },
    typeInner: {
      position: 'relative',
    },
    beforeColor: {
      backgroundColor: theme.palette.common.white,
    },
    afterColor: {
      backgroundColor: '#C9E4FF',
    },
    titleArea: {
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
      height: '50px',
      position: 'relative',
      '& h2': {
        textAlign: 'center',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        position: 'absolute',
        top: '65%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
      },
      [theme.breakpoints.up('sm')]: {
        height: '70px',
      },
    },
    contentArea: {
      width: '100%',
      padding: `0 ${theme.spacing(3)}px`,
      paddingBottom: 0,
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    },
    workContentName: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '& p': {
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    allow: {
      position: 'absolute',
      top: '50%',
      display: 'none',
      '&::after': {
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        border: '0px',
        borderTop: `solid 4px ${theme.palette.common.white}`,
        borderRight: `solid 4px ${theme.palette.common.white}`,
        position: 'absolute',
        top: '50%',
        marginTop: '-4px',
        content: "''",
      },
    },
    allowBefore: {
      left: '15px',
      paddingRight: '20px',
      '&::after': {
        transform: 'rotate(-135deg)',
        left: 0,
      },
    },
    allowAfter: {
      right: '15px',
      paddingLeft: '20px',
      '&::after': {
        transform: 'rotate(45deg)',
        left: 0,
      },
    },
    activeAllow: {
      display: 'inline-block',
    },
    slideDots: {
      textAlign: 'center',
      position: 'absolute',
      bottom: '2%',
      height: '30px',
      width: '100%',
      left: 0,
      zIndex: 3,
    },
    dot: {
      display: 'inline-block',
      position: 'relative',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      margin: '4px',
      background: 'rgba(50,50,50,0.8)',
      cursor: 'pointer',
      transition: 'transform 0.2s linear',
    },
    activeDot: {
      transform: 'scale(1.3)',
      '&::after': {
        content: "''",
        position: 'absolute',
        width: '10px',
        height: '10px',
        top: '3px',
        left: '3px',
        borderRadius: '50%',
        background: 'rgba(255,255,255,0.9)',
      },
    },
  }),
);

/**
 * material-uiコンポーネントの既存スタイル打ち消し
 */
const StyledDialog = withStyles((theme) => ({
  paper: {
    overflow: 'inherit',
  },
  paperFullWidth: {
    maxWidth: '80%',
    margin: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '444px',
    },
  },
  paperScrollPaper: {
    maxHeight: 'none',
  },
}))(Dialog);

type ClothingTypeDialogProps = {
  type: string;
  beforePhotoUrl: string | null;
  afterPhotoUrl: string | null;
  open: boolean;
  handleClose: () => void;
  clothingTypeName: string;
  workContentName: string;
};

const PhotoModal: FC<ClothingTypeDialogProps> = ({
  type,
  beforePhotoUrl: beforePhoto,
  afterPhotoUrl: afterPhoto,
  open,
  handleClose,
  clothingTypeName,
  workContentName,
}) => {
  const classes = useStyles();
  const initSlideIndex = type === 'After' ? 1 : 0;
  const [slideIndex, setSlideIndex] = useState(initSlideIndex);
  const history = useHistory();

  // ダイアログが閉じられた際、
  // Before/Afteの切り替えで更新されたstateを初期値に戻す
  const exitDialog = useCallback(() => {
    setSlideIndex(initSlideIndex);
  }, [initSlideIndex]);

  // 写真スライドのアクティブ切り替え
  const handleChangeIndex = useCallback((index: number) => {
    setSlideIndex(index);
  }, []);

  // ダイアログを閉じる
  const closeDialog = useCallback(() => {
    history.goBack();
    handleClose();
  }, [handleClose, history]);

  // ブラウザバックでダイアログを閉じる
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (open) {
      window.onpopstate = () => {
        handleClose();
      };

      return () => {
        window.onpopstate = null;
      };
    }
  }, [open, handleClose]);

  return (
    <>
      <StyledDialog
        open={open}
        onClose={closeDialog}
        onExited={() => exitDialog()}
        fullWidth
        maxWidth="xs"
        className={classes.PhotoModal}
      >
        <Box>
          <Box onClick={closeDialog} className={classes.closeButton}>
            <CancelRoundedIcon style={{ fontSize: 35 }} />
          </Box>
          <Paper className={classes.typeArea}>
            <Box className={classes.typeInner}>
              <SwipeableViews enableMouseEvents index={slideIndex}>
                <Typography
                  component="p"
                  variant="h6"
                  className={`${classes.type} ${classes.beforeColor}`}
                >
                  Before
                </Typography>
                <Typography
                  component="p"
                  variant="h6"
                  className={`${classes.type} ${classes.typeAfterActive} ${classes.afterColor}`}
                >
                  After
                </Typography>
              </SwipeableViews>
            </Box>
          </Paper>
          <DialogTitle
            className={`${classes.titleArea} ${
              slideIndex === 1 && classes.afterColor
            }`}
          >
            {clothingTypeName}
          </DialogTitle>
          <DialogContent
            className={`${classes.contentArea} ${
              slideIndex === 1 && classes.afterColor
            }`}
          >
            <Box className={classes.photoBox}>
              <SwipeableViews
                enableMouseEvents
                index={slideIndex}
                onChangeIndex={handleChangeIndex}
                slideStyle={{ overflow: 'hidden' }}
              >
                <Box className={classes.photoBoxInner}>
                  <PinchZoom imgPath={beforePhoto || NO_IMAGE} />
                </Box>
                <Box className={classes.photoBoxInner}>
                  <PinchZoom imgPath={afterPhoto || NO_IMAGE} />
                </Box>
              </SwipeableViews>
              <Box className={classes.slideDots}>
                <span
                  className={`${classes.dot} ${
                    slideIndex === 0 && classes.activeDot
                  }`}
                />
                <span
                  className={`${classes.dot} ${
                    slideIndex === 1 && classes.activeDot
                  }`}
                />
              </Box>
              <Box
                className={`${classes.allow}  ${classes.allowBefore} ${
                  slideIndex === 1 && classes.activeAllow
                }`}
                onClick={() => handleChangeIndex(0)}
              />
              <Box
                className={`${classes.allow}  ${classes.allowAfter} ${
                  slideIndex === 0 && classes.activeAllow
                }`}
                onClick={() => handleChangeIndex(1)}
              />
            </Box>
          </DialogContent>
          <DialogContent
            className={`${classes.contentArea} ${
              slideIndex === 1 && classes.afterColor
            }`}
          >
            <Box className={classes.workContentName}>
              <Typography component="p" variant="h6">
                {workContentName}
              </Typography>
            </Box>
          </DialogContent>
        </Box>
      </StyledDialog>
    </>
  );
};

export default PhotoModal;
