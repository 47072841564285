import React, { FC, useState, useRef, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CustomCard from 'components/common/CustomCard';
import { Box, Theme } from '@material-ui/core';
import { WorkType, WorkSide, WorkingResult } from 'api/works/works.dto';
import { workSides } from 'app/constants';
import { fabric } from 'fabric';
import {
  WORKAREA_WIDTH,
  WORKAREA_HEIGHT,
  WORKAREA_IMAGE_WIDTH,
  WORKAREA_IMAGE_TOP,
  WORKAREA_IMAGE_LEFT,
  createCircle,
  setBackgroundText,
} from './ClothingImage';

// スタイル定義
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      overflow: 'unset',
    },
    header: {
      paddingTop: '10px',
      paddingBottom: '10px',
      textAlign: 'center',
    },
    mediaWrap: {
      borderBottom: `1px solid${theme.palette.divider}`,
    },
    cardContent: {
      borderTop: `1px solid${theme.palette.divider}`,
      textAlign: 'center',
      padding: theme.spacing(0),
    },
    cardContentInner: {
      '& >div:last-of-type': {
        borderLeft: `1px solid${theme.palette.divider}`,
      },
    },
    result: {
      fontFamily: theme.typography.fontFamily,
      '& >div:first-of-type': {
        fontSize: theme.typography.h5.fontSize,
        paddingTop: theme.spacing(1),
      },
    },
  }),
);

interface PropsType {
  workId: string;
  workType: WorkType;
  workContentName?: string | null;
  workSide?: WorkSide | null;
  workLocation?: string | null;
  clothingTypePicture?: string | null;
  workingResult: WorkingResult;
}
const WorkDetail: FC<PropsType> = ({
  workId,
  workType,
  workSide,
  workLocation: propsWorkLocation,
  clothingTypePicture,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [workLocation, setWorkLocation] = useState<any>();
  const workAreaRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<fabric.StaticCanvas | null>(null);
  interface ResultPropsType {
    resultLabel: string;
  }

  useEffect(() => {
    if (workAreaRef.current) {
      const htmlCanvas = document.getElementById(
        `canvas${workId}`,
      ) as HTMLCanvasElement;
      htmlCanvas.width = workAreaRef.current.clientWidth;
      htmlCanvas.height = htmlCanvas.width * (WORKAREA_HEIGHT / WORKAREA_WIDTH);
      const canvas = new fabric.StaticCanvas(`canvas${workId}`, {
        enableRetinaScaling: false,
      });
      canvas.setZoom(htmlCanvas.width / WORKAREA_WIDTH);
      canvasRef.current = canvas;
    }
  }, [workId]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const setBackground = () => {
        fabric.Image.fromURL(clothingTypePicture || '', (img: fabric.Image) => {
          img.scaleToWidth(WORKAREA_IMAGE_WIDTH);
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            left: WORKAREA_IMAGE_LEFT,
            top: clothingTypePicture ? WORKAREA_IMAGE_TOP : -16,
          });
          setBackgroundText(canvas);
        });
      };

      if (workLocation) {
        if (workLocation.x) {
          canvas.add(
            createCircle({
              x: workLocation.x * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_LEFT,
              y: workLocation.y * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_TOP,
            }),
          );
        } else {
          canvas.loadFromDatalessJSON(workLocation, setBackground);

          return;
        }
      }
      setBackground();
    }
  }, [clothingTypePicture, workLocation, workType]);

  useEffect(() => {
    setWorkLocation(propsWorkLocation && JSON.parse(propsWorkLocation));
  }, [propsWorkLocation]);

  return (
    <CustomCard className={classes.card}>
      <Box position="relative">
        <div ref={workAreaRef}>
          <canvas id={`canvas${workId}`} data-testid="work-area" />
        </div>
        {workSide && (
          <Box
            position="absolute"
            bgcolor={workSides[workSide].mainColor}
            borderRadius="50%"
            border={2}
            borderColor={workSides[workSide].border}
            width="15%"
            height="15%"
            padding="8%"
            top="10px"
            right="10px"
          >
            <Box
              position="absolute"
              left="0"
              top="50%"
              margin="-.7em 0 0"
              width="100%"
              textAlign="center"
              component="span"
              color={workSides[workSide].contrastText}
              fontWeight={700}
              data-cy="workside-label"
            >
              {workSides[workSide].label}
            </Box>
          </Box>
        )}
      </Box>
    </CustomCard>
  );
};
export default WorkDetail;
