/**
 * 作業結果一覧ページ
 *************************************************/
import React, { FC, useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography, Divider, Box, CardMedia } from '@material-ui/core';
import { baseStyles } from 'components/common/BaseStyles';
import { pageStyles } from 'components/WorkResult/Styles';
import { WorkSide, WorkDto, WorkType } from 'api/works/works.dto';
import ActionButton from 'components/common/ActionButton';
import { getWorksByClothingId } from 'api/works/works.api';
import { staffComment } from 'app/constants';
import {
  failLoading,
  createErrorObject,
  endLoading,
  startLoading,
} from 'modules/commonModule';
import Photo, { WorkResultLocationState } from './Photo';
import WorkDetail from './WorkDetail';

const WorkResultPage: FC<{}> = () => {
  const location = useLocation<WorkResultLocationState>();
  const {
    orderId,
    clothingId,
    tagNo,
    clothingTypeCode,
    clothingTypeName,
    clothingTypePictureFront,
    clothingTypePictureBack,
  } = location.state;
  const baseClasses = baseStyles();
  const classes = pageStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [workListDto, setWorkListDto] = useState<WorkDto[]>([]);

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());
        const works = await getWorksByClothingId(clothingId);
        setWorkListDto(works);
      } catch (e) {
        // データ取得失敗時、衣類情報一覧へリダイレクト
        history.push('/', {
          orderId,
          setScroll: true,
        });
        dispatch(
          failLoading(createErrorObject('データ取得に失敗しました。', e, true)),
        );
      } finally {
        setTimeout(() => {
          dispatch(endLoading());
        }, 400);
      }
    })();
  }, [clothingId, dispatch, history, orderId]);

  /**
   * 画面遷移：衣類情報一覧へ
   */
  const clothinglist = useCallback(async () => {
    dispatch(startLoading());
    setTimeout(() => {
      history.push('/', {
        orderId,
        setScroll: true,
      });
    }, 400);
  }, [dispatch, history, orderId]);

  return (
    <>
      <main>
        <section className={classes.mainVisualSec}>
          <Box className={classes.root}>
            <Box className={classes.button}>
              <Box className={classes.inner}>
                <CardMedia
                  className={classes.media}
                  image={`${process.env.PUBLIC_URL}/clothingImage_${clothingTypeCode}.png`}
                />
              </Box>
            </Box>
            <Box className={classes.mainCopy}>
              <Typography component="h1">
                <Typography
                  component="span"
                  variant="caption"
                  className={classes.clothingTypeName}
                >
                  {clothingTypeName}
                </Typography>
                <Typography
                  component="span"
                  variant="h3"
                  className={classes.tagNo}
                >
                  {`タグ番号：${tagNo}`}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </section>
        <section className={classes.catchSec}>
          <Box className={classes.secInner}>
            <Typography
              component="p"
              className={`${classes.catch} ${classes.catchHead}`}
            >
              「おせっかい」サービス報告
            </Typography>
            <Typography
              component="p"
              className={`${classes.catch} ${classes.catchBody}`}
            >
              お客様からお預かりしたお洋服への
              <br />
              修理・染抜き等の作業内容となります。
            </Typography>
          </Box>
        </section>
        <section className={classes.orderSec}>
          <Box className={classes.secInner}>
            <Typography component="h2" className={baseClasses.secTitle}>
              作業内容
            </Typography>
            <Divider className={baseClasses.secDivider} />
            <Grid
              container
              wrap="wrap"
              justify="space-between"
              className={classes.workList}
            >
              {workListDto.map((work) => (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  className={classes.workItem}
                  key={work.workId}
                >
                  <Grid item xs={12} className={classes.workContentName}>
                    <Typography component="h3" variant="h5">
                      <Box>
                        <img
                          src={`${process.env.PUBLIC_URL}/${work.workType}.png`}
                          alt="icon"
                        />
                      </Box>
                      {work.workType === WorkType.StainRemoval
                        ? '染抜き'
                        : work.workContentName}
                    </Typography>
                  </Grid>

                  <Grid item xs={7} className={classes.workComment}>
                    <Box className={classes.workCommentInner}>
                      <Box className={classes.balloon}>
                        <Box className={classes.says}>
                          <Typography component="p" variant="body1">
                            <span
                              // 文字列に含まれる<br>タグを認識させるため、文字列をHTMLに変換
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html:
                                  staffComment[work.workingResult].comment,
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.staffIcon}>
                        <img
                          src={staffComment[work.workingResult].icon}
                          alt="staff"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={5} className={classes.clothingImage}>
                    <WorkDetail
                      workId={work.workId}
                      workType={work.workType}
                      workContentName={work.workContentName}
                      workSide={work.workSide}
                      workLocation={work.workLocation}
                      clothingTypePicture={
                        work.workSide === WorkSide.Back
                          ? clothingTypePictureBack
                          : clothingTypePictureFront
                      }
                      workingResult={work.workingResult}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Photo
                      type="Before"
                      beforePhoto={work.beforePhoto}
                      afterPhoto={work.afterPhoto}
                      clothingTypeName={clothingTypeName}
                      workContentName={work.workContentName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Photo
                      type="After"
                      beforePhoto={work.beforePhoto}
                      afterPhoto={work.afterPhoto}
                      clothingTypeName={clothingTypeName}
                      workContentName={work.workContentName}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </section>
        <Box className={baseClasses.button}>
          <ActionButton fullWidth color="primary" onClick={clothinglist}>
            他の衣類を確認する
            <Box className={baseClasses.buttonAllow} />
          </ActionButton>
        </Box>
      </main>
    </>
  );
};

export default WorkResultPage;
