import React from 'react';
import Card, { CardProps } from '@material-ui/core/Card';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

/**
 * スタイル定義
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '10px',
      boxShadow: 'none',
    },
  }),
);

const CustomCard = (props: CardProps) => {
  const { ...other } = props;
  const classes = useStyles(props);

  return <Card classes={classes} {...other} />;
};

export default CustomCard;
