import { WorkingResult, WorkSide } from 'api/works/works.dto';

export const API_NAME = 'OsekkaiAPI';

export const NO_IMAGE = `${process.env.PUBLIC_URL}/noImage.png`;

export const workSides = {
  [WorkSide.Front]: {
    label: '表',
    mainColor: '#ffffff',
    border: '#494949',
    contrastText: '#494949',
  },
  [WorkSide.Back]: {
    label: '裏',
    mainColor: '#494949',
    border: '#494949',
    contrastText: '#ffffff',
  },
};

export const staffComment = {
  [WorkingResult.Perfect]: {
    comment: '「おせっかいサービス」させていただきました。',
    icon: `${process.env.PUBLIC_URL}/staff_01.png`,
  },
  [WorkingResult.Almost]: {
    comment:
      'お洋服の状態を拝見した結果、できる限り「おせっかいサービス」させていただきました。',
    icon: `${process.env.PUBLIC_URL}/staff_01.png`,
  },
  [WorkingResult.Part]: {
    comment:
      'お洋服の状態を拝見した結果、可能な範囲で「おせっかいサービス」させていただきました。',
    icon: `${process.env.PUBLIC_URL}/staff_01.png`,
  },
  [WorkingResult.CouldNot]: {
    comment:
      '大変申し訳ございません。<br />処置を試みましたがご期待に沿えませんでした。',
    icon: `${process.env.PUBLIC_URL}/staff_02.png`,
  },
  [WorkingResult.NotRequired]: {
    comment:
      '依頼品の状態を確認させていただき、状態が良く、おせっかい不要と判断しました。',
    icon: `${process.env.PUBLIC_URL}/staff_03.png`,
  },
  [WorkingResult.NotYet]: {
    comment: '',
    icon: 'none',
  },
};

export const IMAGE_MIME = 'image/jpeg';
export const IMAGE_BASE64_PREFIX = `data:${IMAGE_MIME};base64,`;
export const IMAGE_QUALITY = 1;
