import { API } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { ClothingDto } from './clothings.dto';

const path = '/clothings/customer';

/**
 * 衣類情報を取得
 * @param orderId 注文Id
 */
export const getClothingsByOrderId = async (orderId: string) => {
  const clothingListDto: ClothingDto[] = await API.get(
    API_NAME,
    `${path}/list/${orderId}`,
    {},
  );

  return clothingListDto;
};
