/**
 * 作業種別
 */
export enum WorkType {
  /** 毛玉取り */
  LintRemoval = 'LintRemoval',
  /** 修理 */
  Repair = 'Repair',
  /** 染み抜き */
  StainRemoval = 'StainRemoval',
}

/**
 * 作業箇所表裏
 */
export enum WorkSide {
  /** 表 */
  Front = 'Front',
  /** 検査NG */
  Back = 'Back',
}

/**
 * 作業結果
 */
export enum WorkingResult {
  /** 未作業 */
  NotYet = 'NotYet',
  /** 完璧にできた */
  Perfect = 'Perfect',
  /** ほぼできた	 */
  Almost = 'Almost',
  /** 一部できた */
  Part = 'Part',
  /** できなかった */
  CouldNot = 'CouldNot',
  /** する必要無し */
  NotRequired = 'NotRequired',
}

/**
 * 作業一覧情報
 */
export class WorkDto {
  /** 作業Id */
  readonly workId!: string;

  /** 作業種別 */
  readonly workType!: WorkType;

  /** 作業内容名 */
  readonly workContentName!: string;

  /** 作業箇所表裏 */
  readonly workSide!: WorkSide;

  /** 作業箇所 */
  readonly workLocation!: string | null;

  /** 作業前写真 */
  readonly beforePhoto!: string | null;

  /** 作業後写真 */
  readonly afterPhoto!: string | null;

  /** 作業結果 */
  readonly workingResult!: WorkingResult;
}
