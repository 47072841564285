import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ClothinglistPage from 'components/ClothinglistPage/ClothingListPage';
import Footer from 'components/common/Footer';
import WorkResultPage from 'components/WorkResult/WorkResultPage';

const Default: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/workresult" component={WorkResultPage} />
        <Route exact path="/" component={ClothinglistPage} />
        <Route path="/:paramOrderId" component={ClothinglistPage} />
        <Route render={() => <p>not found!.</p>} />
      </Switch>
      <Footer />
    </>
  );
};

export default Default;
