import { Storage, Auth } from 'aws-amplify';
import moment from 'moment';

/**
 * エラーログのアップロード
 * @param errorObject エラーオブジェクト
 */
export const uploadErrorLog = async (
  screenTitle: string,
  errorObject: {
    message: string;
    summary: string;
    detail: string;
  },
) => {
  try {
    const now = new Date();
    const user = await Auth.currentAuthenticatedUser();
    const errorText = `日時:\n${moment(now).format(
      'YYYY/MM/DD HH:mm:ss.SSS',
    )}\n\nUA:\n${window.navigator.userAgent}\n\nユーザー:\n${
      user.username
    }\n\n画面:\n${screenTitle}\n\nメッセージ:\n${
      errorObject.message
    }\n\n概要:\n${errorObject.summary}\n\n詳細:\n${errorObject.detail}`;

    // eslint-disable-next-line no-console
    console.log(errorText);

    const s3PutResponse: { key?: string } = await Storage.put(
      `.logs/osekkaiWork/${moment(now).format('YYYYMMDD')}/${moment(now).format(
        'YYYYMMDDHHmmssSSS',
      )}.log`,
      errorText,
    );

    return s3PutResponse.key;
  } catch (e) {
    // 何もしない
  }

  return undefined;
};
