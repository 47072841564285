/*
 * スタイル定義
 * 作業結果一覧ページ
 */
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { fontTheme } from 'components/common/Theme';

export const pageStyles = makeStyles((theme: Theme) =>
  createStyles({
    secInner: {
      width: '100%',
      maxWidth: '1280px',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    catchSec: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(15),
        paddingBottom: theme.spacing(10),
      },
    },
    catch: {
      textAlign: 'center',
      lineHeight: 1.7,
      fontFamily: fontTheme.fontFamily.mincho,
      [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
      },
    },
    catchHead: {
      marginBottom: theme.spacing(7),
      letterSpacing: '.14em',
      fontSize: theme.typography.h3.fontSize,
      color: theme.typography.h3.color,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.h1.fontSize,
        color: theme.typography.h1.color,
      },
    },
    catchBody: {
      letterSpacing: '.12em',
      fontSize: theme.typography.h6.fontSize,
      color: theme.typography.h6.color,
      [theme.breakpoints.up('sm')]: {
        '& span': {
          display: 'block',
          marginBottom: theme.spacing(1),
          fontSize: theme.typography.h4.fontSize,
          color: theme.typography.h4.color,
        },
      },
    },
    orderSec: {
      paddingTop: theme.spacing(9),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(20),
      },
    },
    orderContainer: {
      marginBottom: theme.spacing(5),
    },

    mainVisualSec: {
      backgroundColor: theme.palette.common.white,
    },
    root: {
      position: 'relative',
      overflow: 'hidden',
      maxWidth: '600px',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: theme.palette.common.white,
    },
    button: {
      width: '100%',
      position: 'relative',
      paddingTop: theme.spacing(20),
      paddingLeft: theme.spacing(30),
      paddingRight: theme.spacing(2),
    },
    inner: {
      width: '70%',
      marginLeft: 'auto',
    },
    media: {
      display: 'block',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: 0,
      paddingTop: '80%',
      backgroundPosition: 'top',
    },
    mainCopy: {
      top: '50%',
      left: '5%',
      position: 'absolute',
      zIndex: 3,
    },
    clothingTypeName: {
      display: 'block',
    },
    tagNo: {
      display: 'block',
      fontWeight: theme.typography.fontWeightBold,
    },
    workList: {
      '&:before': {
        content: '""',
        display: 'block',
        height: 0,
        order: 1,
        [theme.breakpoints.up('sm')]: {
          width: '48%',
        },
        [theme.breakpoints.up('md')]: {
          width: '32%',
        },
      },
      '&:after': {
        content: '""',
        display: 'block',
        height: 0,
        [theme.breakpoints.up('sm')]: {
          width: '48%',
        },
        [theme.breakpoints.up('md')]: {
          width: '32%',
        },
      },
    },
    workItem: {
      transition: 'all 1s',
      marginBottom: theme.spacing(10),
      [theme.breakpoints.up('sm')]: {
        width: '48%',
        flexBasis: '48%',
      },
      [theme.breakpoints.up('md')]: {
        width: '32%',
        flexBasis: '32%',
      },
    },
    workContentName: {
      textAlign: 'center',
      backgroundImage: `url(${process.env.PUBLIC_URL}/texture02.png)`,
      marginBottom: theme.spacing(3),
      '& h3': {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
      },
      '& div': {
        width: '19px',
        display: 'inline-block',
        verticalAlign: 'sub',
        marginRight: '10px',
      },
      '& img': {
        width: '100%',
        display: 'block',
      },
    },
    workComment: {
      maxWidth: '60%',
      flexBasis: '60%',
      marginBottom: theme.spacing(2),
    },
    clothingImage: {
      maxWidth: '40%',
      flexBasis: '40%',
      marginBottom: theme.spacing(2),
    },
    balloon: {
      width: '100%',
      marginBottom: '80px',
      position: 'relative',
    },
    workCommentInner: {
      width: '100%',
      position: 'relative',
      paddingRight: theme.spacing(2),
    },
    says: {
      position: 'relative',
      padding: '17px 13px',
      borderRadius: '12px',
      background: '#C9E4FF',
      '&::after': {
        content: "''",
        display: 'inline-block',
        position: 'absolute',
        bottom: '-25px',
        right: '55px',
        border: '16px solid transparent',
        borderRight: '36px solid #C9E4FF',
        transform: 'rotate(230deg)',
      },
      '& p': {
        margin: 0,
        lineHeight: 1.5,
        [theme.breakpoints.up('sm')]: {
          minHeight: 'calc( 1.5em * 4 )',
        },
        [theme.breakpoints.up('lg')]: {
          minHeight: 'calc( 1.5em * 3 )',
        },
      },
    },
    staffIcon: {
      width: '65px',
      position: 'absolute',
      bottom: '-70px',
      right: theme.spacing(2),
      '& img': {
        width: '100%',
        height: 'auto',
      },
    },
  }),
);
