/**
 * 共通フッター
 *************************************************/
import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, useTheme } from '@material-ui/core';

/**
 * スタイル定義
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      marginTop: theme.spacing(16),
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(30),
      },
    },
    footerImage: {
      width: '100%',
      '& img': {
        width: '100%',
        display: 'block',
      },
    },
    footerCopy: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      textAlign: 'center',
    },
  }),
);

const Footer: FC<{}> = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <footer className={classes.footer}>
        <Box>
          <Box className={classes.footerImage}>
            <picture>
              <source
                media={`(min-width: ${theme.breakpoints.values.sm}px)`}
                srcSet={`${process.env.PUBLIC_URL}/footer-pc.png`}
              />
              <source
                media={`(min-width: ${theme.breakpoints.values.xs}px)`}
                srcSet={`${process.env.PUBLIC_URL}/footer-sp.png`}
              />
              <img
                src={`${process.env.PUBLIC_URL}/footer-pc.png`}
                alt="footer"
              />
            </picture>
          </Box>
          <Typography
            component="p"
            variant="caption"
            className={classes.footerCopy}
          >
            <small>copyright &copy; 2020 Rinavis All Right Reserved</small>
          </Typography>
        </Box>
      </footer>
    </>
  );
};
export default Footer;
