import React, { FC, useRef, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CustomCard from 'components/common/CustomCard';
import { NO_IMAGE } from 'app/constants';
import { WorkType, WorkSide } from 'api/works/works.dto';
import { ClothingTypeDto } from 'api/clothingTypes/clothingTypes.dto';
import { fabric } from 'fabric';
import { fontTheme } from 'components/common/Theme';

export const WORKAREA_WIDTH = 456;
export const WORKAREA_HEIGHT = 376;
export const WORKAREA_IMAGE_WIDTH = 296;
export const WORKAREA_IMAGE_TOP = 50;
export const WORKAREA_IMAGE_LEFT = 80;
export const WORKAREA_CIRCLE_RADIUS = 30;
export const WORKAREA_CIRCLE_STROKE_WIDTH = 6.5;

export const createCircle = (point: { x: number; y: number }) => {
  return new fabric.Circle({
    radius: WORKAREA_CIRCLE_RADIUS,
    fill: '',
    left: point.x,
    top: point.y,
    strokeWidth: WORKAREA_CIRCLE_STROKE_WIDTH,
    originX: 'center',
    originY: 'center',
  });
};

export const setBackgroundText = (
  canvas: fabric.Canvas | fabric.StaticCanvas,
) => {
  const textOptions = {
    fontSize: 35,
    fontWeight: 500,
    fontFamily: fontTheme.fontFamily.gothic,
  };
  for (let i = 1; i <= 8; i += 1) {
    canvas.add(
      new fabric.Text(`${i}`, {
        ...textOptions,
        left: Math.floor((i - 1) / 4) * 374 + 32,
        top: ((i - 1) % 4) * 64 + 130,
      }),
    );
  }
  canvas.add(
    new fabric.Text('裏地', {
      ...textOptions,
      left: 16,
      top: 65,
      fontSize: 30,
    }),
  );
};

interface PropsType {
  clothingType?: ClothingTypeDto;
  workType: WorkType;
  workSide?: WorkSide | null;
  workLocation?: string | null;
  setWorkSide?: (workSide: WorkSide) => void;
  setWorkLocation?: (workLocation?: string | null) => void;
  readOnly?: boolean;
}

const ClothingImage: FC<PropsType> = ({
  clothingType,
  workType,
  workSide,
  workLocation: propsWorkLocation,
  setWorkLocation: setOwnerWorkLocation,
  readOnly,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [workLocation, setWorkLocation] = useState<any>();
  const workAreaRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<fabric.Canvas | fabric.StaticCanvas>();

  useEffect(() => {
    if (!canvasRef.current && workAreaRef.current) {
      const htmlCanvas = document.getElementById('canvas') as HTMLCanvasElement;
      htmlCanvas.width = workAreaRef.current.clientWidth;
      htmlCanvas.height = htmlCanvas.width * (WORKAREA_HEIGHT / WORKAREA_WIDTH);
      if (!readOnly) {
        const canvas = new fabric.Canvas('canvas');
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.color = 'red';
        canvas.freeDrawingBrush.width = 8;

        canvasRef.current = canvas;
      } else {
        canvasRef.current = new fabric.StaticCanvas('canvas');
      }
      canvasRef.current.setZoom(htmlCanvas.width / WORKAREA_WIDTH);
    }
  }, [readOnly, setOwnerWorkLocation, workType]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      fabric.Image.fromURL(
        workSide === WorkSide.Front
          ? clothingType?.clothingTypePictureFront || NO_IMAGE
          : clothingType?.clothingTypePictureBack || NO_IMAGE,
        (img: fabric.Image) => {
          img.scaleToWidth(WORKAREA_IMAGE_WIDTH);
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            left: WORKAREA_IMAGE_LEFT,
            top: clothingType ? WORKAREA_IMAGE_TOP : -32,
          });
          setBackgroundText(canvas);
        },
      );
    }
  }, [clothingType, workSide]);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      if (workLocation) {
        if (workLocation.x) {
          canvas.add(
            createCircle({
              x: workLocation.x * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_LEFT,
              y: workLocation.y * WORKAREA_IMAGE_WIDTH + WORKAREA_IMAGE_TOP,
            }),
          );
        } else {
          canvas.loadFromDatalessJSON(
            workLocation,
            canvas.renderAll.bind(canvas),
          );
        }
      }
    }
  }, [workLocation, workType]);

  useEffect(() => {
    setWorkLocation(propsWorkLocation && JSON.parse(propsWorkLocation));
  }, [propsWorkLocation]);

  return (
    <CustomCard>
      <Box>
        <Box>
          <Box position="relative">
            <div ref={workAreaRef} style={{ width: '100%' }}>
              <canvas id="canvas" />
            </div>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};
export default ClothingImage;
