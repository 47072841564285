import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { currentTheme } from 'components/common/Theme';
import {
  makeStyles,
  createStyles,
  Theme,
  MuiThemeProvider,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import LoginPage from 'components/Login/LoginPage';
import { RootState } from 'app/rootReducer';
import { blue } from '@material-ui/core/colors';
import Default from './Default';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 9999,
      backgroundColor: theme.palette.common.white,
      color: blue[300],
    },
  }),
);

const App: React.FC = () => {
  const classes = useStyles();
  const { isLoading } = useSelector((state: RootState) => state.common);

  return (
    <>
      <MuiThemeProvider theme={currentTheme()}>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route path="/" component={Default} />
        </Switch>
        <Backdrop
          className={classes.backdrop}
          open={isLoading}
          data-cy="loading"
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </MuiThemeProvider>
    </>
  );
};

export default App;
