import { API } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { WorkDto } from './works.dto';

const path = '/works/customer';

/**
 * 作業情報一覧を取得
 * @param clothingId 衣類Id
 */
export const getWorksByClothingId = async (clothingId: string) => {
  const workListDto: WorkDto[] = await API.get(
    API_NAME,
    `${path}/list/${clothingId}`,
    {},
  );

  return workListDto;
};
