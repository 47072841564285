import { API } from 'aws-amplify';
import { API_NAME } from 'app/constants';
import { OrderIdDto } from './orders.dto';

const path = '/orders/customer';

/**
 * 注文IDを取得
 * @param email メールアドレス
 * @param ecOrderNo ECサイト注文番号
 */
export const getOrderId = async (email: string, ecOrderNo: string) => {
  const order: OrderIdDto = await API.get(API_NAME, path, {
    queryStringParameters: {
      email,
      ecOrderNo,
    },
  });

  return { orderId: order.orderId };
};
