import React, { FC } from 'react';
import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';

/**
 * スタイル定義
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoWrap: {
      height: '60vw',
      overflow: 'hidden',
      position: 'relative',
      [theme.breakpoints.up('sm')]: {
        height: '60vw',
      },
      [theme.breakpoints.up('md')]: {
        height: '100vh',
      },
    },
    videoSecInner: {
      position: 'relative',
      height: '100%',
      width: '100%',
    },
    videoCover: {
      width: '100%',
      height: '100%',
      background: 'rgb(0,0,0,0.4)',
      top: 0,
      left: 0,
      position: 'absolute',
      zIndex: 2,
    },
    video: {
      transition: 'opacity .5s ease-out',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
      transform: 'translateX(-50%)',
      left: '50%',
      [theme.breakpoints.up('sm')]: {
        height: '100%',
        width: 'auto',
      },
      [theme.breakpoints.up('xl')]: {
        height: 'auto',
        width: '100%',
      },
    },
    mainCopy: {
      top: '50%',
      left: '5%',
      position: 'absolute',
      zIndex: 3,
      transform: 'translateY(-20%)',
      '& img': {
        width: '70%',
      },
      [theme.breakpoints.up('sm')]: {
        '& img': {
          width: '55%',
        },
      },
    },
    scroll: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    scrollText: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '70px',
      zIndex: 2,
      '& span': {
        display: 'block',
        color: theme.palette.common.white,
        fontSize: '1rem',
        letterSpacing: '.1em',
      },
    },
    scrollAllow: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '50px',
      zIndex: 2,
      '& span': {
        display: 'block',
        width: '15px',
        height: '15px',
        transform: 'rotate(-45deg)',
        borderLeft: `1px solid ${theme.palette.common.white}`,
        borderBottom: `1px solid ${theme.palette.common.white}`,
        zIndex: 2,
      },
    },
  }),
);

const Video: FC = () => {
  const classes = useStyles();

  // 動画ファイルの取得
  // SP/TABの場合、通信速度を考慮して軽量なファイルを再生する
  let mainVideoUrl: string;
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
    mainVideoUrl = `${process.env.PUBLIC_URL}/mainVideo.mp4`;
  } else {
    mainVideoUrl = `${process.env.PUBLIC_URL}/mainVideo_pc.mp4`;
  }

  return (
    <Box className={classes.videoWrap}>
      <Box className={classes.videoSecInner}>
        <video
          src={mainVideoUrl}
          className={classes.video}
          autoPlay
          muted
          playsInline
          loop
          preload="auto"
        />
      </Box>
      <Box className={classes.videoCover} />
      <Box className={classes.mainCopy}>
        <picture>
          <img src={`${process.env.PUBLIC_URL}/mainCopy.png`} alt="mainCopy" />
        </picture>
      </Box>
      <Box className={classes.scroll}>
        <Box className={classes.scrollText}>
          <span>scroll</span>
        </Box>
        <Box className={classes.scrollAllow}>
          <span />
        </Box>
      </Box>
    </Box>
  );
};
export default Video;
