import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { ClothingDto } from 'api/clothings/clothings.dto';
import { startLoading } from 'modules/commonModule';
import {
  Box,
  CardActionArea,
  CardMedia,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import CustomCard from '../common/CustomCard';

/**
 * スタイル定義
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActionArea: {
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(8),
      },
    },
    cardMedia: {
      height: 0,
      paddingTop: '50%',
      backgroundPosition: 'top',
    },
    clothingTypeName: {
      marginTop: theme.spacing(1),
    },
    tagNo: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);

interface PropsType {
  orderId: string;
  clothing: ClothingDto;
}
const ClothingDetail: FC<PropsType> = ({ orderId, clothing }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * 画面遷移：作業結果一覧へ
   * @param clothingId 衣類Id
   */
  const workResult = useCallback(async () => {
    dispatch(startLoading());
    setTimeout(() => {
      history.push('/workresult', {
        orderId,
        clothingId: clothing.clothingId,
        tagNo: clothing.tagNo,
        clothingTypeCode: clothing.clothingTypeCode,
        clothingTypeName: clothing.clothingTypeName,
        clothingTypePictureFront: clothing.clothingTypePictureFront,
        clothingTypePictureBack: clothing.clothingTypePictureBack,
      });
    }, 400);
  }, [dispatch, clothing, history, orderId]);

  return (
    <>
      <CustomCard>
        <CardActionArea className={classes.cardActionArea} onClick={workResult}>
          <Box>
            <CardMedia
              className={classes.cardMedia}
              image={`${process.env.PUBLIC_URL}/clothingImage_${clothing?.clothingTypeCode}.png`}
            />
          </Box>
        </CardActionArea>
      </CustomCard>
      <Typography
        component="p"
        variant="caption"
        className={classes.clothingTypeName}
      >
        {clothing?.clothingTypeName}
      </Typography>
      <Typography component="p" variant="caption" className={classes.tagNo}>
        {`タグ番号：${clothing?.tagNo}`}
      </Typography>
    </>
  );
};
export default ClothingDetail;
