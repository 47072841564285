/**
 * 衣類情報一覧ページ
 *************************************************/
import React, { FC, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Grid, Typography, Divider, Box } from '@material-ui/core';
import ClothingDetail from 'components/ClothinglistPage/ClothingDetail';
import { baseStyles } from 'components/common/BaseStyles';
import { pageStyles } from 'components/ClothinglistPage/Styles';
import { getClothingsByOrderId } from 'api/clothings/clothings.api';
import { ClothingDto } from 'api/clothings/clothings.dto';
import { endLoading, startLoading } from 'modules/commonModule';
import Video from './Video';

type LocationState = {
  orderId: string;
  clothings: ClothingDto[];
  setScroll: boolean;
};
const ClothingListPage: FC<{}> = () => {
  const { paramOrderId } = useParams(); // URLパラメータ（orderId）を取得
  const location = useLocation<LocationState>();
  const orderId = paramOrderId || location.state?.orderId;
  const clothings = location.state?.clothings;
  const setScroll = location.state?.setScroll;
  const baseClasses = baseStyles();
  const classes = pageStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [clothingsDto, setClothingsDto] = useState<ClothingDto[]>([]);
  const areaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      try {
        dispatch(startLoading());

        if (orderId) {
          // ログイン画面からの遷移時に衣類情報一覧が渡されている場合、APIからの取得処理はスキップする
          if (!clothings) {
            // APIから衣類情報一覧を取得し、stateへセット
            setClothingsDto(await getClothingsByOrderId(orderId));
          }
        } else {
          // orderIdがなければログイン画面へリダイレクト
          history.push('/login');
          dispatch(endLoading());
        }

        // 画面スクロール初期位置を設定
        if (setScroll && areaRef.current) {
          window.scrollTo(0, areaRef.current.offsetTop);
        }
      } catch (e) {
        history.push('/login');
        dispatch(endLoading());
      } finally {
        setTimeout(() => {
          dispatch(endLoading());
        }, 400);
      }
    })();
  }, [dispatch, history, orderId, clothings, setScroll]);

  return (
    <>
      <main>
        <section className="videoSec">
          <Video />
        </section>
        <section className={classes.catchSec}>
          <Box className={classes.secInner}>
            <Typography
              component="p"
              variant="h4"
              className={`${classes.catch} ${classes.catchHead}`}
            >
              <span>この度は、ご注文いただきまして</span>
              <span>誠にありがとうございます。</span>
            </Typography>
            <Typography
              component="p"
              variant="body1"
              className={`${classes.catch} ${classes.catchBody}`}
            >
              <span>私たちなりに一着一着、</span>
              <span>一生懸命「おせっかい」をさせていただきました。</span>
              <br />
              <span>このお洋服と少しでも長く</span>
              <span>素敵な時間を過ごしていただければと思っております。</span>
            </Typography>
          </Box>
        </section>
        <section className={classes.orderSec}>
          <Box className={classes.secInner}>
            <div ref={areaRef} />
            <Typography component="h2" className={baseClasses.secTitle}>
              ご注文内容
            </Typography>
            <Divider className={baseClasses.secDivider} />
            <Grid
              container
              justify="space-between"
              className={classes.orderContainer}
            >
              <Grid item xs={12} md={6} className={classes.orderItem}>
                <Typography
                  component="h3"
                  variant="h5"
                  className={classes.orderHead}
                >
                  お客様氏名
                </Typography>
                <Typography
                  component="p"
                  variant="h3"
                  className={classes.orderBody}
                >
                  {clothingsDto?.[0]?.customerName}
                  <Typography component="span" variant="caption">
                    様
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderItem}>
                <Typography
                  component="h3"
                  variant="h5"
                  className={classes.orderHead}
                >
                  ご依頼点数
                </Typography>
                <Typography
                  component="p"
                  variant="h1"
                  className={classes.orderBody}
                >
                  {clothingsDto?.[0]?.qty}
                  <Typography component="span" variant="caption">
                    点
                  </Typography>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              wrap="wrap"
              className={classes.clothingList}
              justify="space-between"
            >
              {clothingsDto.map((clothing: ClothingDto) => (
                <Grid
                  item
                  className={classes.clothingItem}
                  key={clothing.clothingId}
                >
                  <ClothingDetail orderId={orderId} clothing={clothing} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </section>
      </main>
    </>
  );
};

export default ClothingListPage;
